import makeRequest from "../index";

export function getCamDetails() {
  return makeRequest({
    uri: `/api/v1/cam_groups/257b9a85-2021-47fe-8a1a-6a494a5db387`,
    method: "GET",
  });
}

export function sendResponse(requestBody) {
  return makeRequest({
    uri: `/api/v1/cam_groups/257b9a85-2021-47fe-8a1a-6a494a5db387/answers`,
    method: "POST",
    body: JSON.stringify(requestBody),
  });
}

export function getReportUrl(id) {
  return makeRequest({
    uri: `/api/v1/cam_groups/${id}/details`,
  });
}

export function getDownloadReport(id) {
  return makeRequest({
    uri: `/api/v1/bms_reports/${id}`,
  });
}