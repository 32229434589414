import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import Hls from "hls.js";
import camConfig from "../camConfig.json";
import {
  getCamDetails,
  getReportUrl,
  sendResponse,
} from "../../src/api/camera.js";
import { deleteCookie, setCookie } from "../helper/cookies";
import toast, { Toaster } from "react-hot-toast";
import downloadPng from "../../src/images/download.png";
import HomePng from "../../src/images/home.png";

function StreamReport() {
  const [data, setData] = useState(camConfig);
  const [currentCam, setCurrentCam] = useState();
  const [btn_disable, setBtn_disable] = useState(false);
  const [verifyPage, setVerifyPage] = useState(false);
  const [verifyData, setVerifyData] = useState();
  const [reportPage, setReportPage] = useState(false);
  const [reportId, setReportId] = useState();
  const [btnLoder, setBtnLoder] = useState(false);
  const [reportData, setReportData] = useState();
  const [actions, setActions] = useState(false);

  let timer = null;
  const videoRef = useRef(null);
  let hls;

  useEffect(() => {
    getCamDetails().then(({ data }) => {
      let result = {};
      data.patrol_cams.forEach((x) => {
        let questions = {};

        x.questions.forEach((y) => {
          questions[y.id] = y.name;
        });

        result[x.id] = {
          name: x.name,
          hls_url: x.hls_url,
          questions,
        };
      });
      setData(result);
      setCurrentCam(Object.keys(result)[0]);
    });
  }, []);

  useEffect(() => {
    if (reportPage) {
      setBtn_disable(true);
      autoRefresh();
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
    };
  }, [reportId]);

  useEffect(() => {
    setBtn_disable(false);
    if (currentCam) {
      if (Hls.isSupported()) {
        hls = new Hls();
        hls.loadSource(data[currentCam].hls_url);
        hls.attachMedia(videoRef.current);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          videoRef.current.play();
        });
      } else if (
        videoRef.current.canPlayType("application/vnd.apple.mpegurl")
      ) {
        videoRef.current.src = data[currentCam].hls_url;
        videoRef.current.play();
      }
    }
  }, [currentCam]);

  //   const handlePrevious = (event) => {
  //     event.preventDefault();
  //     console.log("pre page");
  //     let allCams = Object.keys(data);
  //     let nextCam = allCams[allCams.indexOf(currentCam) - 1];
  //     setCurrentCam(nextCam);
  //   };

  const autoRefresh = () => {
    if (!timer) {
      clearTimeout(timer);
      timer = null;
    }
    fetchReportUrl();
    timer = setTimeout(autoRefresh, 5000);
  };

  const handleNext = (event) => {
    event.preventDefault();
    let allCams = Object.keys(data);
    let nextCam = allCams[allCams.indexOf(currentCam) + 1];
    setCurrentCam(nextCam);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let currentData = { ...data };
    currentData[currentCam].answers ||= {};
    currentData[currentCam].answers[name] = value;
    setData(currentData);
  };

  const copyPath = (key) => {
    setBtn_disable(true);
    navigator.clipboard.writeText(key);
    toast.success("Successfully toasted!");
  };

  const handleChange = (e, indexs, mainIndex) => {
    const { name, value } = e.target;
    const newArray = verifyData.map((item, index) => {
      if (index === mainIndex) {
        let er = [];
        item.data.map((rr, i) => {
          if (indexs === i) {
            er.push({ ...rr, [name]: value });
          } else {
            er.push(rr);
          }
        });
        return { ...item, data: er };
      } else {
        return item;
      }
    });
    setVerifyData(newArray);
  };

  const onSubmit = () => {
    let arrs = [];
    verifyData.map((arr) =>
      arr.data.map((key) =>
        arrs.push({ question_id: key.question_id, answer: key.answer })
      )
    );
    sendResponse({ answers: arrs })
      .then(({ data }) => {
        setReportPage(true);
        setReportId(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loginPage = () => {
    window.location.reload();
    window.location.pathname = `/`;
  };

  const handleSubmit = () => {
    let result = [];
    Object.keys(data).forEach((cam_id) => {
      Object.keys(data[cam_id].answers)?.forEach((question_id) => {
        result.push({
          name: data[cam_id].name,
          submissions: {
            question_id,
            question: data[cam_id].questions[question_id],
            answer: data[cam_id].answers[question_id],
          },
        });
      });
    });

    setVerifyData(
      Array.from(new Set(result.map((s) => s.name))).map((key) => {
        return {
          name: key,
          data: result
            .filter((s) => s.name === key)
            .map((edition) => edition.submissions),
        };
      })
    );
    setVerifyPage(true);
  };

  const handleActions = () => {
    setActions(true);
  };

  const fetchReportUrl = () => {
    setBtnLoder(true);
    getReportUrl(reportId)
      .then(({ data }) => {
        if (data.s3_report_url) {
          setReportData(data);
          setBtnLoder(false);
          setBtn_disable(false);
          setCookie("send_answer", true);
          deleteCookie("stream_report");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (verifyPage) {
    return (
      <div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          {reportData?.s3_report_url ? (
            <div style={{ position: "fixed", padding: 30, right: 0 }}>
              <span
                onClick={() => handleActions()}
                style={{
                  width: "130px",
                  height: "40px",
                  backgroundColor: "#804BDF",
                  color: "#fff",
                  fontWeight: 600,
                  border: 0,
                  borderRadius: 3,
                  fontSize: "0.87rem",
                  cursor: btn_disable ? "not-allowed" : "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 12,
                }}
              >
                Actions
              </span>
              <p></p>
              {actions && (
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <a href={reportData?.s3_report_url} download>
                    <div
                      style={{
                        boxShadow: "rgb(0 0 0 / 35%) 0px 5px 15px",
                        padding: "8px 10px",
                        borderRadius: "50%",
                      }}
                    >
                      <img
                        style={{ width: 30, height: 30 }}
                        src={downloadPng}
                      />
                    </div>
                  </a>
                  <div
                    style={{
                      boxShadow: "rgb(0 0 0 / 35%) 0px 5px 15px",
                      padding: "8px 10px",
                      borderRadius: "50%",
                      cursor: "pointer",
                    }}
                    onClick={() => loginPage()}
                  >
                    <img style={{ width: 30, height: 30 }} src={HomePng} />
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div style={{ position: "fixed", padding: 30, right: 0 }}>
              <button
                style={{
                  width: "130px",
                  height: "40px",
                  backgroundColor: "#804BDF",
                  color: "#fff",
                  fontWeight: 600,
                  border: 0,
                  borderRadius: 3,
                  fontSize: "0.87rem",
                  cursor: btn_disable ? "not-allowed" : "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 12,
                }}
              >
                {btnLoder && <i className="fa fa-spinner fa-spin"></i>}
                <span>Submit</span>
              </button>
            </div>
          )}

          <div>
            {verifyData &&
              verifyData.map((arr, i) => {
                return (
                  <div
                    key={i}
                    style={{
                      // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                      padding: 12,
                    }}
                  >
                    <h1>{arr.name}</h1>
                    {arr.data.map((arrs, index) => {
                      return (
                        <>
                          <p>{arrs?.question}</p>
                          {reportData?.s3_report_url ? (
                            <p>{arrs?.answer}</p>
                          ) : (
                            <input
                              value={arrs?.answer}
                              onChange={(e) => handleChange(e, index, i)}
                              name="answer"
                            />
                          )}
                        </>
                      );
                    })}
                  </div>
                );
              })}
          </div>
        </form>
      </div>
    );
  }

  return (
    <div
      style={{
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
        display: "flex",
        justifyContent: "center",
        margin: "50px",
        borderRadius: 10,
        padding: 20,
        gap: 20,
      }}
    >
      {!currentCam ? (
        <div
          style={{ minHeight: "75vh", display: "flex", alignItems: "center" }}
        >
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flex: 2,
              flexDirection: "column",
            }}
          >
            {/* <p>{data[currentCam].hls_url}</p> */}
            <ReactPlayer
              url={data[currentCam].hls_url}
              playing
              controls
              width="75%"
              height={600}
              config={{
                file: {
                  forceHLS: true,
                  attributes: {
                    crossOrigin: "anonymous",
                  },
                },
              }}
            />

            {/* <video
              ref={videoRef}
              controls
              autoPlay
              width={"75%"}
              height={500}
            /> */}
            <div
              style={{
                display: "flex",
                width: "75%",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              {" "}
              <input
                name="url"
                type="text"
                className="input"
                value={data[currentCam].hls_url}
                style={{ height: "30px" }}
              ></input>
              <button
                onClick={() => copyPath(data[currentCam].hls_url)}
                disabled={btn_disable}
                style={{
                  width: "130px",
                  height: "30px",
                  backgroundColor: "#56CA00",
                  color: "#fff",
                  fontWeight: 600,
                  border: 0,
                  borderRadius: 10,
                  fontSize: "0.87rem",
                  cursor: btn_disable ? "" : "pointer",
                }}
              >
                {btn_disable ? "Copied !" : "Copy Url"}
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <form
              onSubmit={handleNext}
              style={{ maxHeight: "65vh", overflow: "auto" }}
            >
              <p
                style={{
                  fontSize: "1.25rem",
                  fontWeight: 500,
                  color: "rgba(58, 53, 65, 0.87)",
                }}
              >
                <span
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: 600,
                    color: "#000",
                    marginRight: 10,
                  }}
                >
                  Camera:
                </span>
                {data[currentCam].name}
              </p>

              {Object.keys(data[currentCam].questions).map(
                (questionName, index) => {
                  return (
                    <div key={index}>
                      <label
                        style={{
                          fontSize: "1.25rem",
                          color: "rgba(58, 53, 65, 0.87)",
                        }}
                      >
                        {`${index + 1}.`}{" "}
                        {data[currentCam].questions[questionName]}
                        <input
                          name={questionName}
                          type="text"
                          className="input"
                          required
                          onChange={handleInputChange}
                          value={
                            data[currentCam].answers
                              ? data[currentCam].answers.questionName
                              : ""
                          }
                        ></input>
                      </label>
                    </div>
                  );
                }
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  //   alignItems:"flex-end"
                  position: "sticky",
                  bottom: 0,
                  // width: "47%",
                  backgroundColor: "white",
                }}
              >
                {/* <button
            onClick={handlePrevious}
            type="submit"
            style={{
              width: "130px",
              height: "40px",
              backgroundColor: "#56CA00",
              color: "#fff",
              fontWeight: 600,
              border: 0,
              borderRadius: 10,
              fontSize: "0.87rem",
              cursor: "pointer",
            }}
          >
            Prev
          </button> */}
                {Object.keys(data)[Object.keys(data).length - 1] ===
                currentCam ? (
                  <button
                    onClick={handleSubmit}
                    type="submit"
                    style={{
                      width: "130px",
                      height: "40px",
                      backgroundColor: "#804BDF",
                      color: "#fff",
                      fontWeight: 600,
                      border: 0,
                      borderRadius: 10,
                      fontSize: "0.87rem",
                      cursor: "pointer",
                    }}
                  >
                    Submit
                  </button>
                ) : (
                  <button
                    //   onClick={handleNext}
                    type="submit"
                    style={{
                      width: "130px",
                      height: "40px",
                      backgroundColor: "#56CA00",
                      color: "#fff",
                      fontWeight: 600,
                      border: 0,
                      borderRadius: 10,
                      fontSize: "0.87rem",
                      cursor: "pointer",
                    }}
                  >
                    Next
                  </button>
                )}
              </div>
            </form>
          </div>
        </>
      )}
      <Toaster position={"top-right"} />
    </div>
  );
}

export default StreamReport;
