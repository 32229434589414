import React, { useEffect } from "react";
import { toast, Toaster } from "react-hot-toast";
import { deleteCookie, getCookie, setCookie } from "../helper/cookies";
import backgroundAnimation from "../images/background.svg";

function GetStarted() {

  useEffect(() => {
    if(getCookie("send_answer")){
      handleToast()
    }
  }, []);

  const handleToast = () => {
    toast.success("Created Successfully...");
    deleteCookie("send_answer");

  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <img
        src={backgroundAnimation}
        width={"100%"}
        className="svg_animation"
        alt="backgroundAnimation"
      ></img>

      <button
        className="custom-btn btn-1"
        onClick={() => {
          setCookie("stream_report", true);
          window.location.reload();
        }}
      >
        Get Started !
      </button>
      <Toaster position={"top-right"} />
    </div>
  );
}

export default GetStarted;
