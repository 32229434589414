import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
// import MainLayout from "components/layout/mainLayout";
import GetStarted from "../views/getStart";
import StreamReport from "../views/stream-report";
import { getCookie } from "../helper/cookies";

const publicPath = [{ path: "/", component: GetStarted }];

const privateRoute = [
  {
    path: "/stream-report",
    component: StreamReport,
  },
];

export const CustomRoutes = () => {
  const [routeState, setRouteState] = useState({
    privatePath: [],
  });
  const { privatePath, defaultRoute } = routeState;


  useEffect(() => {
    if (getCookie("stream_report")) {
        setRouteState((preState) => ({
                  ...preState,
                  privatePath: privateRoute,
                  defaultRoute: privateRoute[0]?.path,
                }));
    } else {
      setRouteState((prevState) => ({ ...prevState, defaultRoute: "/" }));
    }
  }, []);

  return (
    <Router>
      <Routes>
        {privatePath?.map((route, index) => {
          let Component = route?.component;
          return (
            <Route
              exact
              key={index}
              path={route?.path}
              element={
                getCookie("stream_report") ? (
                //   <MainLayout>
                    <Component />
                //   </MainLayout>
                ) : (
                  <Navigate to={`/`} />
                )
              }
            />
          );
        })}

        {publicPath &&
          publicPath?.map((route, index) => {
            let Component = route?.component;
            return (
              <Route
                exact
                key={index}
                path={route?.path}
                element={
                  getCookie("stream_report") ? (
                    <Navigate to={defaultRoute} />
                  ) : (
                    <Component />
                  )
                }
              />
            );
          })}

        <Route path="*" element={<Navigate to={defaultRoute} />} />
      </Routes>
    </Router>
  );
};
